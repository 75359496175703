import * as React from 'react';
import Link from '@components/Link';
import UEIcon from '@assets/socials/marketplace.svg';
import ArtStationIcon from '@assets/socials/artstation.svg';
import * as styles from './MarketplaceButton.module.scss';


export type Props = {
  url: string;
  marketplace: number;
  className?: string;
};

export default function MarketplaceButton({ url, marketplace }: Props) {
  const isEpicGames = marketplace === 0;
  return (
    <Link
      className={styles.link}
      to={url}
      external
    >
      {isEpicGames ? <UEIcon className={styles.icon}/> : <ArtStationIcon className={styles.icon}/>}
      <span className={styles.text}>{isEpicGames ? 'UE Marketplace' : 'ArtStation'}</span>
    </Link>
  );
}
