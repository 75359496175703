import * as React from 'react';
import { graphql } from 'gatsby';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useProduct, useLibrary, useCart } from '@core/hooks';
import { getSiteMetaData, mailToURL } from '@core/utils';
import { BREAKPOINT, CART, POSITION } from '@core/constants';
import BaseHead from '@components/Head';
import Main from '@components/Main';
import Centerer from '@components/Centerer';
import Sidebar from '@components/Sidebar';
import Content from '@components/Content';
import Title from '@components/Title';
import Gallery from '@components/Gallery';
import ArrayList from '@components/ArrayList';
import Separator from '@components/Separator';
import * as Collapsible from '@components/Collapsible';
import HtmlContent from '@components/HtmlContent';
import Link from '@components/Link';
import FileRow from '@components/library/FileRow';
import ProductView from '@components/store/ProductView';
import License from '@components/store/License';
import Excerpt from '@components/store/Excerpt';
import CartControls from '@components/store/CartControls';
import MarketplaceButton from '@components/store/MarketplaceButton';
import { SiteData, PostgresData, Product, ProductRelease, ProductFile, ProductLink } from '@interface/gatsby';
import { PageProps } from '@interface/common';
import * as styles from './product.module.scss';


const SIDEBAR_BREAKPOINTS = [BREAKPOINT.NAME.DESKTOP, BREAKPOINT.NAME.WIDE, BREAKPOINT.NAME.ULTRA];

type DataType = SiteData & PostgresData<Record<'product', Product>>;

export default function ProductTemplate({ data, isAuthenticating }: PageProps<DataType>) {
  const { isInLibrary } = useLibrary();
  const { getDiscount, addProductToCart, removeFromCart, isInCart, isInBundle } = useCart();
  const { emails } = getSiteMetaData(data);
  const { product } = data.postgres;
  const { hasVideo, latestRelease, galleryImages } = useProduct(product);
  const [isViewOpen, setIsViewOpen] = React.useState(false);

  const inLibrary = React.useMemo(() => isInLibrary(product.id), [product.id, isInLibrary]);
  const inCart = React.useMemo(() => isInCart(product.id, CART.ITEM.PRODUCTS), [product.id, isInCart]);
  const inBundle = React.useMemo(() => isInBundle(product.id), [product.id, isInBundle]);
  const includesVideo = React.useMemo(() => hasVideo(), [product.id, hasVideo]);
  const discount = React.useMemo(() => getDiscount(product.id), [product.id, getDiscount]);

  const handleAddToCartClick = React.useCallback(() => {
    addProductToCart(product.id);
  }, [product.id, addProductToCart]);

  const handleRemoveFromCartClick = React.useCallback(() => {
    removeFromCart(product.id, CART.ITEM.PRODUCTS);
  }, [product.id, removeFromCart]);

  const handleViewOpen = () => setIsViewOpen(true);
  const handleViewClose = () => setIsViewOpen(false);

  return (
    <Main>
      <Centerer
        withTopBarOffset
        withVerticalPadding
        sidebar={POSITION.RIGHT}
        sidebarBreakpoints={SIDEBAR_BREAKPOINTS}
      >
        <Sidebar sticky>
          <Title className={styles.title}>{product.title}</Title>
          <div className={styles.category}>
            <Link to={product.category.url} className="underline accent">
              {product.category.title}
            </Link>
            {latestRelease && ` / v${latestRelease.version} / ${latestRelease.createdAt}`}
          </div>
          <License/>
          <Excerpt>
            <HtmlContent
              tagName="p"
              value={product.shortDescription}
            />
          </Excerpt>
          {latestRelease && (
            <>
              <Collapsible.Single
                title={`Files (${latestRelease.files.length})`}
                triggerClassName={styles.collapsible__trigger}
              >
                <ArrayList<ProductFile>
                  items={latestRelease.files}
                  render={({ item }) => (
                    <FileRow
                      {...item}
                      productId={product.id}
                      showDownloadLink={inLibrary}
                    />
                  )}
                />
              </Collapsible.Single>
              <div className={styles.details}>
                <p>{`Supported platforms: ${latestRelease.platforms.join(', ')}`}</p>
                <p>{`Supported UE versions: ${latestRelease.supports}`}</p>
              </div>
            </>
          )}
          <CartControls
            price={product.price}
            discount={inBundle ? 0 : discount}
            isInCart={inCart}
            isInBundle={inBundle}
            isLoading={isAuthenticating}
            showDiscount={!inBundle}
            showViewButton={inLibrary}
            onAddToCartClick={handleAddToCartClick}
            onRemoveClick={handleRemoveFromCartClick}
            onViewOpen={handleViewOpen}
          />
          <Separator>or look at</Separator>
          <div className={styles.links}>
            <ArrayList<ProductLink>
              items={product.links}
              render={({ item }) => (
                <MarketplaceButton
                  url={item.url}
                  marketplace={item.marketplace}
                />
              )}
            />
          </div>
        </Sidebar>
        <Content>
          <Gallery
            className={styles.gallery}
            items={galleryImages}
            showThumbnails
            showNavOnMouseOver
            hasVideo={includesVideo}
          />
          <Tabs
            disableUpDownKeys
            forceRenderTabPanel
          >
            <TabList>
              <Tab>Description</Tab>
              <Tab>Technical Info</Tab>
              <Tab>Installation</Tab>
              <Tab>Changelog</Tab>
              <Tab>Support</Tab>
            </TabList>
            <TabPanel>
              <HtmlContent value={product.longDescription}/>
            </TabPanel>
            <TabPanel>
              <HtmlContent value={product.technicalInfo}/>
            </TabPanel>
            <TabPanel>
              <HtmlContent value={product.installation}/>
            </TabPanel>
            <TabPanel>
              <div className="format">
                <h4>Release history</h4>
              </div>
              <ArrayList<ProductRelease>
                items={product.releases}
                render={({ item }) => (
                  <>
                    <h4 className={styles.changelog__title}>{`${item.version} - ${item.createdAt}`}</h4>
                    <HtmlContent className={styles.changelog__content} value={item.changes}/>
                  </>
                )}
              />
            </TabPanel>
            <TabPanel>
              <div className="format">
                <h4>Communication</h4>
                <p>
                  {`For quick answer responses or help with product, please use email - write us to `}
                  <Link to={mailToURL({
                    to: emails.support,
                    subject: `Question about "${product.title}"`,
                    body: [
                      'Hello Rebel Thorp,',
                      '',
                      'My text...',
                      '',
                      '',
                      '---',
                      'UE version: X.XX',
                      'Package version: X.X.X',
                      'Video/screenshots attached: Yes',
                      '',
                    ],
                  })}
                  >
                    {emails.support}
                  </Link>
                </p>
              </div>
            </TabPanel>
          </Tabs>
        </Content>
      </Centerer>
      {inLibrary && latestRelease && (
        <ProductView
          isOpen={isViewOpen}
          id={product.id}
          cover={product.cover}
          title={product.title}
          description={product.category.description}
          version={latestRelease.version}
          createdAt={latestRelease.createdAt}
          files={latestRelease.files}
          onRequestClose={handleViewClose}
        />
      )}
    </Main>
  );
}

export function Head({ data }: PageProps<DataType>) {
  const { product } = data.postgres;

  return (
    <BaseHead
      title={`${product.title} - ${product.category.description}`}
      description={product.meta.description}
      keywords={product.meta.keywords}
      opengraph={product.opengraph}
      twitter={product.twitter}
    />
  );
}

export const query = graphql`
    query ProductBySlug($slug: String!) {
        site {
            siteMetadata {
                emails {
                    support
                }
            }
        }
        postgres {
            product: productBySlug(slug: $slug) {
                id
                title
                price
                slug
                meta
                shortDescription
                longDescription
                technicalInfo
                installation
                category {
                    slug
                    url
                    title
                    description
                }
                cover {
                    childImageSharp {
                        gatsbyImageData(layout: CONSTRAINED)
                    }
                }
                images {
                    original: childImageSharp {
                        gatsbyImageData
                    }
                    thumbnail: childImageSharp {
                        gatsbyImageData(layout: FIXED, width: 120)
                    }
                    meta
                }
                opengraph: openGraphImage(name: "opengraph", width: 1200, height: 630) {
                    ...OpenGraphImage
                }
                twitter: openGraphImage(name: "twitter-card", width: 1200, height: 600) {
                    ...OpenGraphImage
                }
                links: productLinksList(orderBy: MARKETPLACE_ASC) {
                    marketplace
                    url
                }
                videos: productVideosList(orderBy: CREATED_AT_DESC, first: 1) {
                    videoId
                    start
                    end
                }
                latestRelease: productReleasesList(orderBy: CREATED_AT_DESC, first: 1) {
                    id
                    version
                    platforms
                    supports
                    changes
                    createdAt
                    files: productFilesByReleaseIdList(orderBy: [TARGET_ASC, NAME_DESC]) {
                        id
                        name
                        description
                        target
                        size
                        supports
                    }
                }
                releases: productReleasesList(orderBy: CREATED_AT_DESC) {
                    version
                    changes
                    createdAt
                }
            }
        }
    }
`;
